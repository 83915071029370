export default {
  methods: {
    getMemberPublicId () {
      return this.$cookies.get('memberPublicId')
    },

    isAuthenticatedMember () {
      let memberPublicId = this.getMemberPublicId()
      return memberPublicId !== undefined && memberPublicId !== null && memberPublicId !== ''
    }
  }
}
