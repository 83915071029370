import { defineStore } from 'pinia'
import { axiosInstance } from '@/library'
import { ref, getCurrentInstance } from 'vue'

export const useMemberStore = defineStore('member', () => {
  const member = ref({})
  const error = ref(null)

  const loadMemberDetails = async (memberPublicId) => {
    try {
      const response = await axiosInstance.get(`${window.VUE_APP_GRANJA}/api/v1/members/${memberPublicId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.status === 200) {
        member.value = response.data
      }
    } catch (error) {
      error.value = error.message
    }
  }

  const updateMemberDetails = async () => {
    await axiosInstance.put(`${window.VUE_APP_GRANJA}/api/v1/members/${member.value.memberPublicId}`, member.value, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  const appInstance = getCurrentInstance()
  const memberPublicId = appInstance.appContext.config.globalProperties.$cookies.get('memberPublicId')
  if (memberPublicId)
    loadMemberDetails(memberPublicId)

  return {
    member,
    error,
    loadMemberDetails,
    updateMemberDetails
  }
})
