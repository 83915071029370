<template>
  <div>
    <h2>Contact details</h2>
    <div class="input-row">
      <div class="label">Name:</div>
      <div v-if="editName">
        <input type="text" v-model="memberStore.member.firstName" placeholder="First name"
          @keyup.enter="memberStore.updateMemberDetails()" v-focus />
        <input type="text" v-model="memberStore.member.lastName" placeholder="Last name"
          @keyup.enter="memberStore.updateMemberDetails()" />
        <span v-if="memberStore.error" class="errorMessage">&nbsp;{{ memberStore.error }}</span>
      </div>
      <div v-else>
        {{ memberStore.member.firstName }} {{ memberStore.member.lastName }}
        <icon @click="editName = true" :path="mdiPencil" size="16" />
      </div>
    </div>
    <div class="input-row">
      <div class="label">Email:</div>
      <div v-if="editEmail">
        <input type="text" v-model="memberStore.member.email" placeholder="email address"
          @keyup.enter="memberStore.updateMemberDetails()" v-focus />
        <span v-if="memberStore.error" class="errorMessage">&nbsp;{{ memberStore.error }}</span>
      </div>
      <div v-else>
        {{ memberStore.member.email }}
        <icon @click="editEmail = true" :path="mdiPencil" size="16" />
      </div>
    </div>
    <div class="input-row">
      <div class="label">Phone:</div>
      <div v-if="editPhone">
        <input type="text" v-model="memberStore.member.phone" placeholder="Phone number"
          @keyup.enter="memberStore.updateMemberDetails()" v-focus />
        <span v-if="memberStore.error" class="errorMessage">&nbsp;{{ memberStore.error }}</span>
      </div>
      <div v-else>
        {{ memberStore.member.phone }}
        <icon @click="editPhone = true" :path="mdiPencil" size="16" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, watch, ref } from 'vue'
import { mdiPencil } from '@mdi/js'
import Icon from 'vue3-icon'
import { useMemberStore } from '@/stores/member'

export default {
  name: 'MemberContactDetails',
  components: {
    Icon
  },
  setup () {
    const appInstance = getCurrentInstance()
    const memberPublicId = appInstance.appContext.config.globalProperties.$cookies.get('memberPublicId')
    const memberStore = useMemberStore()
    memberStore.loadMemberDetails(memberPublicId)

    const editEmail = ref(false)
    const editName = ref(false)
    const editPhone = ref(false)

    watch(() => memberStore.success, () => {
      editEmail.value = false
      editName.value = false
      editPhone.value = false
    })

    return {
      mdiPencil, memberStore,
      editEmail, editName, editPhone
    };
  },

  directives: {
    focus: {
      mounted (el) {
        el.focus()
      }
    }
  }
}
</script>

